<template>
  <div class="Logo animate__animated animate__slow" :class="HandleLogo(isLogo, isScrollLogo)">
    <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/common/img/logo_black.png" />
    <p>你所见的，就是我们。你所见的，就是未来。</p>
    <div class="line">
      <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Logo_line_l.png" />
      <p>具有全球竞争力的一站式国际教育集团</p>
      <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Logo_line_r.png" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isLogo: {
      type: Boolean,
      default: false
    },
    isScrollLogo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    HandleLogo(isFade, isScroll) {
      if (isFade && isScroll) {
        return 'animate__fadeIn ScrollLogo'
      } else if (isFade) {
        return 'animate__fadeIn'
      } else {
        return ''
      }
    }
  }
}
</script>
<style lang="less" scoped>
.Logo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: all 1.5s;
  background: url('http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Home_bg.png') center bottom no-repeat;
  background-size: 100% auto;
  margin-top: 50px;
  > img {
    width: 463px;
    transition: width 1.5s ease-in-out;
  }
  > p {
    font-size: 32px;
    margin-top: 75px;
    transition: all 1.5s ease-in-out;
  }
  .line {
    width: 1440px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    font-size: 0;
    transition: all 1.5s ease-in-out;
    img {
      width: 450px;
      transition: width 1.5s ease-in-out;
    }
    p {
      font-size: 22px;
      line-height: 1;
      color: #111111;
      transition: all 1.5s ease-in-out;
      padding: 0 30px;
    }
  }
  &.ScrollLogo {
    height: 264px;
    background: none;
    > img {
      width: 235px;
    }
    > p {
      font-size: 16px;
      margin-top: 38px;
    }
    .line {
      width: 730px;
      margin-top: 17px;
      img {
        width: 254px;
      }
      p {
        font-size: 12px;
        padding: 0 8px;
      }
    }
  }
}
</style>
