//函数防抖
const debounce = (func, wait = 100, immediate = true) => {
  let timer
  return (...args) => {
    let context = this
    if (timer) clearTimeout(timer)
    if (immediate) {
      let callNow = !timer
      timer = setTimeout(() => {
        timer = null
      }, wait)
      if (callNow) func.apply(context, args)
    } else {
      timer = setTimeout(() => {
        func.apply(context, args)
      }, wait)
    }
  }
}

export { debounce }
