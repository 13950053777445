<template>
  <div class="Project" :style="{ backgroundImage: `url(${ProjectList[ProjectActive].photoUrls})` }" v-if="ProjectList.length > 0">
    <div class="Project_box">
      <div class="title">
        <p>西索核心项目</p>
        <span></span>
      </div>
      <div class="item">
        <div class="item_box">
          <div class="Page">
            <p>{{ ProjectActive >= 10 ? ProjectActive : `0${ProjectActive + 1}` }}</p>
            <span></span>
            <p>{{ Project.length >= 10 ? ProjectList.length : `0${ProjectList.length}` }}</p>
          </div>
          <div class="content" :style="{ backgroundImage: `url(${ProjectList[ProjectActive].photoUrl})` }">
            <div class="box">
              <div class="text">
                <ul>
                  <li v-for="(item, index) in ProjectList" :key="index" :class="ProjectActive == index ? 'isTextActive' : ''">
                    <p>{{ item.photoTitle }}</p>
                    <span>{{ item.photoDescribe }}</span>
                  </li>
                </ul>
                <div class="text_height">
                  <p>{{ ProjectList[ProjectActive].photoTitle }}</p>
                  <span>{{ ProjectList[ProjectActive].photoDescribe }}</span>
                </div>
              </div>
              <div class="item_btn">
                <p><a :href="ProjectList[ProjectActive].photoRemarks" target="_blank">了解详情</a></p>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div class="Project_btn">
          <div class="btn" @click="ProjectChange(true)"></div>
          <div class="btn" @click="ProjectChange(false)"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['Project'],
  mounted() {},
  data() {
    return {
      ProjectActive: 0,
      ProjectList: []
    }
  },
  methods: {
    ProjectChange(e) {
      if (e) {
        this.ProjectActive--
        if (this.ProjectActive < 0) {
          this.ProjectActive = this.Project.length - 1
        }
      } else {
        this.ProjectActive++
        if (this.ProjectActive >= this.Project.length) {
          this.ProjectActive = 0
        }
      }
    }
  },
  watch: {
    Project(e) {
      this.ProjectList = e
    }
  }
}
</script>
<style lang="less" scoped>
.Project {
  width: 100%;
  overflow: hidden;
  background-size: 1920px 750px;
  background-position: center top;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  transition: all 1s;
  .Project_box {
    width: 1440px;
    overflow: hidden;
    padding: 80px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    .title {
      color: #ffffff;
      p {
        font-size: 32px;
      }
      span {
        display: block;
        width: 50px;
        height: 4px;
        margin-top: 17px;
        background: #ffffff;
      }
    }
    .item {
      width: 760px;
      overflow: hidden;
      min-height: 570px;
      background: #ffffff;
      padding: 85px 130px 0 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      .item_box {
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        transition: all 1s;
        .Page {
          width: 130px;
          overflow: hidden;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          p {
            font-size: 20px;
            font-family: 'Bold';
            color: #111111;
            &:first-child {
              color: #2d64fa;
            }
          }
          span {
            width: 1px;
            height: 70px;
            background: #111111;
            margin: 33px 0;
          }
        }
        .content {
          width: 500px;
          overflow: hidden;
          min-height: 400px;
          padding: 23px 25px;
          transition: all 1s;
          .box {
            width: 100%;
            overflow: hidden;
            min-height: 354px;
            background: #ffffff;
            padding: 48px 59px 68px 59px;
            .text {
              width: 100%;
              height: max-content;
              overflow: hidden;
              position: relative;
              li {
                width: 100%;
                height: max-content;
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                transition: opacity 1s;
                z-index: 5;
                // padding: 48px 59px 0 59px;
                background: #ffffff;
                > p {
                  font-size: 24px;
                  color: #111111;
                  font-family: 'Bold';
                  text-align: center;
                }
                > span {
                  display: block;
                  font-size: 14px;
                  color: #111111;
                  line-height: 30px;
                  text-align: justify;
                  margin-top: 32px;
                }
                &.isTextActive {
                  opacity: 1 !important;
                  z-index: 10;
                }
              }
              .text_height {
                width: 100%;
                overflow: hidden;
                min-height: 262px;
                opacity: 0;
                > p {
                  font-size: 24px;
                  color: #111111;
                  font-family: 'Bold';
                  text-align: center;
                }
                > span {
                  display: block;
                  font-size: 14px;
                  color: #111111;
                  line-height: 30px;
                  text-align: justify;
                  margin-top: 32px;
                }
              }
            }
            .item_btn {
              width: 100%;
              overflow: hidden;
              margin-top: 23px;
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              cursor: pointer;
              p {
                a {
                  color: #111111;
                  font-size: 16px;
                }
              }
              span {
                display: block;
                width: 80px;
                height: 2px;
                background: #111111;
                margin-top: 7px;
              }
            }
          }
        }
      }
      .Project_btn {
        width: 314px;
        height: 85px;
        overflow: hidden;
        padding-left: 130px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn {
          width: 35px;
          height: 19px;
          cursor: pointer;
          &:first-child {
            background: url('http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/object_click_l2.png') center center no-repeat;
            background-size: 100% 100%;
            transition: all 0.2s;
            &:hover {
              background-image: url('http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/object_click_l.png');
            }
          }
          &:last-child {
            background: url('http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/object_click_r.png') center center no-repeat;
            background-size: 100% 100%;
            transition: all 0.2s;
            &:hover {
              background-image: url('http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/object_click_r2.png');
            }
          }
        }
      }
    }
  }
}
</style>
