<template>
  <div id="Home">
    <Head :isWhite="isWhiteLogo" :isMenu="isMenu" @isMenuShow="isMenuShow" />
    <div class="Banner" v-show="isBanner">
      <div class="Img">
        <ul>
          <li v-for="(item, index) in Banner" :key="index" :class="BannerActive == index ? 'isActive' : ''">
            <p class="animate__animated animate__slow" :class="BannerActive == index ? 'animate__fadeInDown' : ''">{{ item.photoName }}</p>
            <span class="animate__animated animate__slow" :class="BannerActive == index ? 'animate__fadeInUp' : ''">{{ item.photoTitle }}</span>
            <img :src="item.photoUrl" />
          </li>
        </ul>
      </div>
      <div class="indicator">
        <ul>
          <li v-for="(item, index) in Banner.length" :key="index" :class="BannerActive == index ? 'isActive' : ''" @click="BannerActive = index">
            <p>{{ `0${index + 1}` }}</p>
            <span></span>
            <span></span>
          </li>
        </ul>
      </div>
      <div class="Icon">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="Scroll_animate" :class="ScrollisShow ? 'ScrollShow' : ''">
      <p :class="isScroll == 1 ? 'Animate4' : ''"></p>
    </div>
    <div class="container" ref="container" v-show="this.isScroll == 1">
      <Logo :isLogo="isLogo" :isScrollLogo="isScrollLogo" />
      <Project v-show="isOtherContainer" :Project="ProjectList" />
      <Map v-show="isOtherContainer" />
      <Strive v-show="isOtherContainer" :isShow="isOtherContainer" :isAnimate="isStrive" ref="Strive" />
      <News v-show="isOtherContainer" :News="NewList" />
      <Foot v-show="isOtherContainer" @isBackTop="isBackTop" />
    </div>
    <BackTop v-show="isOtherContainer" @isBackTop="isBackTop" />
  </div>
</template>

<script>
import Head from '../../components/Head'
import Map from '../../components/Home/Map'
import Project from '../../components/Home/Project'
import Logo from '../../components/Home/Logo'
import Strive from '../../components/Home/Strive'
import News from '../../components/Home/News'
import Foot from '../../components/Foot'
import BackTop from '../../components/BackTop'
import { debounce } from '../../plugins/debounce'
export default {
  components: {
    Head,
    Map,
    Project,
    Logo,
    Strive,
    News,
    Foot,
    BackTop
  },
  data() {
    return {
      isWhiteLogo: true,
      isBanner: true,
      BannerSetTime: null,
      UpTime: null,
      BannerActive: 0,
      Banner: [],
      isScroll: 0,
      ScrollisShow: false,
      ScrollSetTime: null,
      isLogo: false,
      isScrollLogo: false,
      isOtherContainer: false,
      isStrive: false,
      isMenu: false,
      ProjectList: [],
      NewList: [],
      SrollKey: true
    }
  },
  beforeMount() {
    this.GetHomeData()
  },
  mounted() {
    clearInterval(this.BannerSetTime)
    this.BannerAuto()
    window.addEventListener('wheel', debounce(this.handleScroll), false)
  },
  methods: {
    async GetHomeData() {
      let { code, result, message } = await this.$Http.GetHomeData()
      if (code == 200) {
        this.Banner = result.banner
        this.ProjectList = result.model
        this.NewList = result.news
      } else {
        this.$message.error(message)
      }
    },
    ScrollBg(e) {
      switch (e) {
        case 0:
          return ''
        case 1:
          return 'Animate1'
        case 2:
          return 'Animate2'
        case 3:
          return 'Animate3'
        case 4:
          return 'Animate4'
      }
    },
    BannerAuto() {
      this.BannerSetTime = setInterval(() => {
        if (this.BannerActive < this.Banner.length - 1) {
          this.BannerActive++
        } else {
          this.BannerActive = 0
        }
      }, 5000)
    },
    handleScroll(e) {
      if (this.isOtherContainer) {
        if (this.$refs.container.scrollTop >= this.$refs.Strive.$el.offsetTop - 1200) {
          this.isStrive = true
        } else {
          this.isStrive = false
        }
      } else {
        this.isMenu = false
      }
      let direction = e.deltaY > 0 ? 'down' : 'up' //deltaY为正则滚轮向下，为负滚轮向上
      if (direction == 'down') {
        clearInterval(this.BannerSetTime)
        clearTimeout(this.ScrollSetTime)
        clearTimeout(this.UpTime)
        // this.isScroll++
        // if (this.isScroll == 1) {
        //   this.isScroll = 1
        //   if (this.isWhiteLogo) {
        //     this.isWhiteLogo = false
        //     this.ScrollSetTime = setTimeout(() => {
        //       this.ScrollisShow = false
        //       this.isBanner = false
        //       this.isLogo = true
        //     }, 1000)
        //   }
        // } else {
        //   this.ScrollisShow = true
        //   this.BannerAuto()
        // }
        if (!this.isLogo) {
          this.isScroll = 1
          this.ScrollisShow = true
          this.isWhiteLogo = false
          this.ScrollSetTime = setTimeout(() => {
            this.ScrollisShow = false
            this.isBanner = false
            this.isLogo = true
            this.isScrollLogo = true
            if (!this.isOtherContainer) {
              setTimeout(() => {
                this.isOtherContainer = true
              }, 500)
            }
          }, 1000)
        }
        // if (this.isLogo) {
        //   this.isScrollLogo = true
        //   if (!this.isOtherContainer) {
        //     setTimeout(() => {
        //       this.isOtherContainer = true
        //     }, 500)
        //   }
        // } else {
        //   this.isScrollLogo = false
        //   this.isOtherContainer = false
        // }
      }
      if (direction == 'up') {
        // if (this.$refs.container.scrollTop == 0 && !this.isScrollLogo) {
        //   clearTimeout(this.ScrollSetTime)
        //   clearInterval(this.BannerSetTime)
        //   this.isScroll = 0
        //   if (!this.isBanner) {
        //     this.isBanner = true
        //   }
        //   if (!this.ScrollisShow) {
        //     this.ScrollisShow = true
        //   }
        //   if (!this.isWhiteLogo) {
        //     this.isWhiteLogo = true
        //   }
        //   if (this.isLogo) {
        //     this.isLogo = false
        //   }
        //   // if (this.ScrollisShow) {
        //   //   this.ScrollisShow = false
        //   // }
        //   this.BannerAuto()
        // } else if (this.$refs.container.scrollTop == 0 && this.isScrollLogo) {
        //   this.isScrollLogo = false
        //   if (this.isOtherContainer) {
        //     setTimeout(() => {
        //       this.isOtherContainer = false
        //     }, 1000)
        //   }
        // }
        if (this.$refs.container.scrollTop <= 100) {
          this.isScrollLogo = false
          clearTimeout(this.UpTime)
          clearInterval(this.BannerSetTime)
          clearTimeout(this.ScrollSetTime)
          this.UpTime = setTimeout(() => {
            this.isScroll = 0
            this.isOtherContainer = false
            this.isBanner = true
            this.ScrollisShow = true
            this.isWhiteLogo = true
            this.isLogo = false
            setTimeout(() => {
              this.ScrollisShow = false
            }, 1000)
            this.BannerAuto()
          }, 800)
        }
      }
    },
    isMenuShow(e) {
      this.isMenu = e
    },
    isBackTop() {
      let _this = this
      let ScrollTime = setInterval(() => {
        if (_this.$refs.container.scrollTop > 0) {
          _this.$refs.container.scrollTop = _this.$refs.container.scrollTop - 50
        }
        if (_this.$refs.container.scrollTop == 0) {
          clearInterval(ScrollTime)
        }
      }, this.$refs.container.scrollTop / 1000000)
    }
  },
  destroyed() {
    window.removeEventListener('wheel', this.handleScroll())
  }
}
</script>

<style lang="less">
@import '../../assets/Home/css/Home.less';
</style>
