import Vue from 'vue'
import Home from './Home.vue'
import '../../assets/common/css/common.less'
import 'animate.css'
import '../../plugins/fontsLoader'
import '../../plugins/element'
import Http from '../../request/http'

Vue.prototype.$Http = Http
Vue.config.productionTip = false

new Vue({
  render: (h) => h(Home)
}).$mount('#Home')
