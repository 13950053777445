<template>
  <div class="News">
    <div class="box">
      <div class="top">
        <div class="title">
          <p>新闻中心</p>
          <span></span>
        </div>
        <div class="Tabs">
          <ul>
            <li v-for="(item, index) in Tabs" :key="index" :class="TabsActive == index ? 'TabsActive' : ''" @click="TabsActive = index">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
      <div class="list">
        <ul :class="TabsActive == 0 ? 'isNews' : ''">
          <li v-for="(item, index) in dynamic" :key="index">
            <a :href="`/NewsDetails/${item.newsId}-26.html`">
              <img :src="item.newsImg" />
              <p>{{ item.newsFullTitle }}</p>
              <span>{{ item.newsPublishTime.split('T')[0] }}</span>
            </a>
          </li>
        </ul>
        <ul :class="TabsActive == 1 ? 'isNews' : ''">
          <li v-for="(item, index) in enterprise" :key="index">
            <a :href="`/NewsDetails/${item.newsId}-27.html`">
              <img :src="item.newsImg" />
              <p>{{ item.newsFullTitle }}</p>
              <span>{{ item.newsPublishTime.split('T')[0] }}</span>
            </a>
          </li>
        </ul>
        <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/News_list_line.png" />
      </div>
      <div class="btn">
        <p><a href="/Dynamic.html">查看更多</a></p>
        <span></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['News'],
  data() {
    return {
      TabsActive: 0,
      Tabs: ['动态新闻', '企业风采'],
      dynamic: [],
      enterprise: []
    }
  },
  watch: {
    News(e) {
      this.dynamic = e.dnews
      this.enterprise = e.qnews
    }
  }
}
</script>
<style lang="less" scoped>
.News {
  width: 100%;
  overflow: hidden;
  padding: 117px 0 135px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    width: 1440px;
    overflow: hidden;
    flex-shrink: 0;
    padding: 0 20px;
    .top {
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        p {
          font-size: 32px;
          line-height: 1;
          color: #111111;
          letter-spacing: 3px;
        }
        span {
          display: block;
          width: 50px;
          height: 4px;
          background: #e9e9e9;
          margin-top: 17px;
        }
      }
      .Tabs {
        ul {
          display: flex;
          align-items: center;
          li {
            width: 140px;
            overflow: hidden;
            font-size: 20px;
            font-family: 'Bold';
            border-bottom: 2px solid #d1d1d1;
            color: #d1d1d1;
            text-align: center;
            padding: 10px 0;
            cursor: pointer;
            transition: all 0.8s;
            &:last-child {
              margin-left: 2px;
            }
            &.TabsActive {
              color: #2d64fa;
              border-color: #2d64fa;
            }
          }
        }
      }
    }
    .list {
      width: 100%;
      height: 380px;
      overflow: hidden;
      margin-top: 50px;
      position: relative;
      padding-top: 320px;
      ul {
        width: 1400px;
        height: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        opacity: 0;
        transition: opacity 1s;
        &.isNews {
          height: 320px;
          opacity: 1;
        }
        li {
          width: 345px;
          height: 320px;
          overflow: hidden;
          a {
            display: block;
            width: 100%;
            height: 320px;
            overflow: hidden;
            border: 1px solid #d1d1d1;
            color: #111111;
            transition: border 0.1s;
            &:hover {
              border: 3px solid #2d64fa;
            }
            img {
              width: 100%;
              height: 194px;
            }
            p {
              width: 300px;
              height: 75px;
              font-size: 20px;
              padding: 10px 0;
              line-height: 30px;
              margin: 0 auto;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-align: justify;
            }
            span {
              width: 300px;
              display: block;
              font-size: 14px;
              line-height: 1;
              margin: 0 auto;
            }
          }
        }
      }
      > img {
        width: 100%;
        overflow: hidden;
        font-size: 0;
        margin-top: 20px;
      }
    }
    .btn {
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      margin-top: 30px;
      p {
        a {
          font-size: 16px;
          color: #111111;
          transition: color 0.3s;
        }
        width: 80px;
        overflow: hidden;
        text-align: center;
      }
      span {
        display: block;
        width: 80px;
        height: 2px;
        background-color: #000000;
        margin-top: 5px;
        transition: background-color 0.3s;
      }
      &:hover {
        p {
          a {
            color: #2d64fa;
          }
        }
        span {
          background-color: #2d64fa;
        }
      }
    }
  }
}
</style>
