<template>
  <div class="BackTop">
    <ul>
      <li @click="BackTop">
        <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/common/img/BackTop_icon1.png" width="24px" />
        <div class="Hover">返回<br />顶部</div>
      </li>
      <li @click="isContactShow = !isContactShow" v-ClickOutside="isContactHidden">
        <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/common/img/BackTop_icon2.png" width="26px" />
        <div class="Hover">联系<br />我们</div>
      </li>
    </ul>
    <div class="contact" :class="isContactShow ? 'isContactShow' : ''">
      <ul>
        <li>
          <div class="icon">
            <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/common/img/BackTop_icon3.png" width="43px" />
            <p>商务合作</p>
          </div>
          <div class="text">
            <p>国际合作部</p>
            <p><span>电话:</span> +86 188 0719 0425</p>
            <p><span>邮箱：</span> kyrene.yang@csoschool.com</p>
          </div>
        </li>
        <li>
          <div class="icon">
            <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/common/img/BackTop_icon4.png" width="42px" />
            <p>联系我们</p>
          </div>
          <div class="text">
            <p>400-686-9191 中国（CN）</p>
            <p>416-225-2666 加拿大（CA）</p>
            <p><span>邮箱：info@csoedu.com</span></p>
          </div>
        </li>
        <li>
          <div class="icon">
            <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/common/img/BackTop_icon5.png" width="40px" />
            <p>微信平台</p>
          </div>
          <div class="text">
            <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/common/img/BackTop_ewm.png" width="86px" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isContactShow: false
    }
  },
  methods: {
    BackTop() {
      this.$emit('isBackTop')
    },
    isContactHidden() {
      this.isContactShow = false
    }
  }
}
</script>
<style lang="less" scoped>
.BackTop {
  > ul {
    width: 48px;
    height: 106px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: fixed;
    right: 105px;
    bottom: 70px;
    z-index: 99;
    li {
      width: 48px;
      height: 48px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      &:first-child {
        background: #eaeffe;
      }
      &:last-child {
        background: #2d64fa;
      }
      .Hover {
        width: 48px;
        height: 48px;
        overflow: hidden;
        font-size: 14px;
        line-height: 16px;
        padding-top: 7px;
        color: #ffffff;
        background: #96b1fc;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(48px);
        opacity: 0;
        transition: all 0.5s;
      }
      &:hover {
        .Hover {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }
  .contact {
    width: 0;
    height: 0;
    overflow: hidden;
    position: fixed;
    right: 173px;
    bottom: 70px;
    z-index: 99;
    padding: 10px;
    background: #eaeffe;
    opacity: 0;
    transition: all 0.8s;
    &.isContactShow {
      width: 470px;
      height: 400px;
      opacity: 1;
    }
    li {
      width: 100%;
      height: 120px;
      overflow: hidden;
      background: #ffffff;
      display: flex;
      align-items: center;
      &:not(:first-child) {
        margin-top: 10px;
      }
      .icon {
        width: 140px;
        height: 120px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p {
          font-size: 18px;
          color: #111111;
          margin-top: 15px;
        }
      }
      .text {
        p {
          font-size: 16px;
          line-height: 1;
          color: #2d64fa;
          &:not(:first-child) {
            margin-top: 6px;
          }
          span {
            color: #111111;
          }
        }
      }
    }
  }
}
</style>
