const City = [
  {
    city: '北京',
    imgSrc: 'http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/beijing.png',
    pos: {
      top: 172,
      left: 484
    }
  },
  {
    city: '西安',
    imgSrc: 'http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/xian.png',
    pos: {
      top: 255,
      left: 397
    }
  },
  {
    city: '苏州',
    imgSrc: 'http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/suzhou.png',
    pos: {
      top: 287,
      left: 519
    }
  },
  {
    city: '上海',
    imgSrc: 'http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/shanghai.png',
    pos: {
      top: 315,
      left: 544
    }
  },
  {
    city: '武汉',
    imgSrc: 'http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/wuhan.png',
    pos: {
      top: 322,
      left: 431
    }
  },
  {
    city: '成都',
    imgSrc: 'http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/chengdu.png',
    pos: {
      top: 336,
      left: 336
    }
  },
  {
    city: '广州',
    imgSrc: 'http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/guangzhou.png',
    pos: {
      top: 458,
      left: 436
    }
  },
  {
    city: '深圳',
    imgSrc: 'http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/shenzhen.png',
    pos: {
      top: 443,
      left: 464
    }
  },
  {
    city: '台北',
    imgSrc: 'http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/taibei.png',
    pos: {
      top: 422,
      left: 536
    }
  }
]

export default City
