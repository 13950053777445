<template>
  <div class="Strive" ref="Strive">
    <div class="Strive_container animate__animated animate__slow" :class="isAnimate ? 'animate__fadeInLeft isOpacity' : ''">
      <p>为给你一个可预见的未来，我们愿倾注<br />全部努力，用心做教育。</p>
      <div class="list">
        <p>西索——教育领先品牌</p>
        <ul>
          <li>
            <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Strive_icon1.png" width="56px" />
            <p>科技赋能教育</p>
          </li>
          <li>
            <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Strive_icon2.png" width="52px" />
            <p>与国际接轨</p>
          </li>
          <li>
            <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Strive_icon3.png" width="51px" />
            <p>全方位一体化服务</p>
          </li>
          <li>
            <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Strive_icon4.png" width="52px" />
            <p>多品牌塑造差异化</p>
          </li>
          <li>
            <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Strive_icon5.png" width="57px" />
            <p>权威专业教研团队</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="Srive_img animate__animated animate__slow" ref="SriveImg" :class="isAnimate ? 'animate__fadeInRight isOpacity' : ''">
      <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Strive_img2.png" width="100%" />
    </div>
    <div class="Strive_line"></div>
  </div>
</template>
<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    isAnimate: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isShow(e) {
      if (e) {
        this.$refs.Strive.style.marginLeft = `-${(1920 - document.documentElement.offsetWidth) / 3}px`
        if (document.documentElement.offsetWidth <= 1440) {
          this.$refs.SriveImg.style.height = '550px'
          this.$refs.Strive.style.height = '779px'
        }
      }
    }
  },
  mounted() {}
}
</script>
<style lang="less" scoped>
.Strive {
  width: 1920px;
  height: 843px;
  overflow: hidden;
  position: relative;
  margin: 60px 0 117px 0;
  .Strive_container {
    width: 1150px;
    height: 580px;
    overflow: hidden;
    background: url('http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Strive_img1.png') center top no-repeat;
    padding: 105px 0 0 260px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    opacity: 0;
    > p {
      font-size: 40px;
      font-family: 'Bold';
      line-height: 60px;
      color: #ffffff;
    }
    .list {
      width: 100%;
      overflow: hidden;
      margin-top: 105px;
      > p {
        font-size: 24px;
        color: #ffffff;
      }
      ul {
        display: flex;
        align-items: center;
        margin-top: 60px;
        li {
          height: 100px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-between;
          p {
            font-size: 16px;
            color: #ffffff;
          }
          &:not(:first-child) {
            margin-left: 50px;
          }
        }
      }
    }
  }
  .Srive_img {
    width: 1680px;
    height: 614px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 4;
    opacity: 0;
  }
  .isOpacity {
    opacity: 1;
    transform: opacity 0.5s;
  }
}
</style>
